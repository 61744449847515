import React from 'react';
import CardLayout from '../common/CardLayout';
import ReactTable from 'react-table-6';
import { Grid, Segment, Form, Button } from 'semantic-ui-react'
import DateTextBox from '../common/DateTextBox';
import Viewer from 'react-viewer';
import '../../css/ReactViewer.css'
import { printURL } from "../../utils/printURL";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

class CardScannedDocument extends React.Component {
  constructor() {
    super();

    this.state = {
      visible: false,
      images: [],
      docStartDate: null,
      docEndDate: null,
      scanStartDate: null,
      scanEndDate: null,
      expiredStartDate: null,
      expiredEndDate: null,
      documentCategory: null,
      scanDivision: null,
      ownerDivision: null,
      docNo: null,
      scanUser: null,
      encounterDoctor: null,
      documentType: null,
    }
  }

  componentDidMount() {
    this.props.onGetScannedDocumentOptions()
  }

  showScannedDoc = (docProps) => {
    let imageList = [{ src: docProps.original.document_image }]
    this.setState({ visible: true, images: imageList })
  }

  handleSearchClick = () => {
    const {
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      documentType,
    } = this.state
    this.props.onGetScannedDocument({
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      documentType,
    })
    this.setState({ visible: false, images: [] })
  }

  handleSearchUserChange = async (e) => {
    let searchText = e.target.value
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(() => {
        this.props.onGetSearchDoctorList({ searchText });
      }, 400)
    });
  }

  handleSearchDoctorChange = async (e) => {
    let searchText = e.target.value
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(() => {
        this.props.onGetSearchDoctorList({ searchText });
      }, 400)
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.callGetScannedDoc !== this.props.callGetScannedDoc && this.props.callGetScannedDoc !== null) {
      this.handleSearchClick();
    }
  }

  handlePageChange = (page) => {
    const {
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      documentType,
    } = this.state
    this.props.onPaginationChange({
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      documentType,
      currentScannedDocPage: page + 1
    })
  }

  render() {

    const { intl } = this.props;
    return (
      <CardLayout
        titleText='Scanned Document'
        headerColor='brown'
        closeable={this.props.closeable}
        toggleable={this.props.toggleable}
        defaultBackground={this.props.defaultBackground}
        hideHeaderIcon={this.props.hideHeaderIcon}
        loading={this.props.scannedDocLoading}
      >
        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <Grid>
            <Grid.Column width={8} >
              <Segment
                id="container"
                style={{ height: '675px', width: 'auto' }}
                className="grey inverted"
              >
                <Viewer
                  visible={this.state.visible}
                  onClose={() => {
                    this.setState({ visible: false });
                  }}
                  // activeIndex={this.state.imgActiveIndex}
                  images={this.state.images}
                  container={document.getElementById('container')}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                <Form.Group inline>
                  {/* <Form.Field >
                    <label>Encounter</label>
                    <Input icon='search' />
                  </Form.Field> */}
                  <Form.Field>
                    <Button
                      color='blue'
                      onClick={this.handleSearchClick}
                    ><FormattedMessage id="ค้นหา" /></Button>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                    <Form.Dropdown
                      data-testid="ScannedDocument-Dropdown-DocumentType"
                      placeholder='แสดงทุกประเภท'
                      // value={this.state.documentCategory || ""}
                      label={intl.formatMessage({ id: "ประเภทเอกสาร" })}
                      fluid
                      clearable
                      search
                      selection
                      options={this.props.scannedDocOptions.docCategory ? this.props.scannedDocOptions.docCategory : []}
                      onChange={(e, data) => {
                        this.setState({ documentCategory: data.value })
                        this.props.onGetDocumentType({ documentCategory: data.value })
                      }}
                    />
                  </Form.Field>
                  <Form.Field >
                  </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                    <Form.Dropdown
                      placeholder='แสดงทุกประเภท'
                      // value={this.state.documentType || ""}
                      label={intl.formatMessage({ id: "ชื่อเอกสาร" })}
                      fluid
                      clearable
                      search
                      selection
                      options={this.props.scannedDocOptions.docType ? this.props.scannedDocOptions.docType : []}
                      onChange={(e, data) => {
                        this.setState({ documentType: data.value })
                      }}
                    />
                  </Form.Field>
                  <Form.Field >
                    <Form.Input fluid label={intl.formatMessage({ id: "เลขที่เอกสาร" })} onChange={(e) => {
                      this.setState({ docNo: e.target.value })
                    }} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                    <Form.Dropdown
                      id="ScannedDocument-Dropdown-ScanDivision"
                      placeholder='แสกงทุกแผนกที่สแกนเอกสาร'
                      label={intl.formatMessage({ id: "แผนกที่สแกนเอกสาร" })}
                      fluid
                      clearable
                      search
                      selection
                      options={this.props.scannedDocOptions.division ? this.props.scannedDocOptions.division : []}
                      onChange={(e, data) => {
                        this.setState({ scanDivision: data.value })
                      }}
                    />
                  </Form.Field>
                  <Form.Field >
                    <Form.Dropdown
                      search
                      fluid
                      clearable
                      selection
                      label={intl.formatMessage({ id: "ผู้สแกนเอกสาร" })}
                      loading={this.props.userListLoading}
                      icon='search'
                      onChange={(e, { value }) => this.setState({ scanUser: value })}
                      onSearchChange={this.handleSearchUserChange}
                      options={this.props.userList ? this.props.userList : []}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                    <Form.Dropdown
                      placeholder='แสกงทุกแผนกเจ้าของสแกนเอกสาร'
                      label={intl.formatMessage({ id: "แผนกเจ้าของสแกนเอกสาร" })}
                      fluid
                      search
                      clearable
                      selection
                      options={this.props.scannedDocOptions.division ? this.props.scannedDocOptions.division : []}
                      onChange={(e, data) => {
                        this.setState({ ownerDivision: data.value })
                      }}
                    />
                  </Form.Field>
                  <Form.Field >
                    <Form.Dropdown
                      search
                      fluid
                      selection
                      clearable
                      label={intl.formatMessage({ id: "แพทย์" })}
                      loading={this.props.doctorListLoading}
                      icon='search'
                      onChange={(e, { value }) => this.setState({ encounterDoctor: value })}
                      onSearchChange={this.handleSearchDoctorChange}
                      options={this.props.doctorList ? this.props.doctorList : []}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline >
                  <Form.Field width={5}>
                    วันที่ของเอกสารสแกน
                  </Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.docStartDate}
                      onChange={(date) => this.setState({ docStartDate: date })}
                    />
                  </Form.Field>
                  <Form.Field width={1}>
                    ถึง
                  </Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.docEndDate}
                      onChange={(date) => this.setState({ docEndDate: date })}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field width={5}>วันที่สแกนเอกสาร</Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.scanStartDate}
                      onChange={(date) => this.setState({ scanStartDate: date })}
                    />
                  </Form.Field>
                  <Form.Field width={1}>
                    ถึง
                  </Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.scanEndDate}
                      onChange={(date) => this.setState({ scanEndDate: date })}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field width={5}>วันที่หมดอายุของเอกสาร</Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.expiredStartDate}
                      onChange={(date) => this.setState({ expiredStartDate: date })}
                    />
                  </Form.Field>
                  <Form.Field width={1}>ถึง</Form.Field>
                  <Form.Field>
                    <DateTextBox
                      value={this.state.expiredEndDate}
                      onChange={(date) => this.setState({ expiredEndDate: date })}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <ReactTable
                manual
                page={this.props.currentPage - 1}
                pages={this.props.numberOfPage}
                onPageChange={this.handlePageChange}
                showPageSizeOptions={false}
                style={{ height: 250, backgroundColor: '#FFFFFF' }}
                pageSize={this.props.scannedDoc ? (this.props.scannedDoc.length < 5 ? 5 : this.props.scannedDoc.length) : 5}
                data={this.props.scannedDocData}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (e) => {
                        if (this.clickTimeout !== null) {
                          if (this.state.oneDaySelected === rowInfo.index) {
                            clearTimeout(this.clickTimeout);
                            this.clickTimeout = null;
                            this.setState({ visible: true })
                          }
                        } else {
                          this.clickTimeout = setTimeout(() => {
                            clearTimeout(this.clickTimeout);
                            this.clickTimeout = null;
                          }, 500);
                        }
                      },
                    };
                  } else {
                    return {};
                  }
                }}
                columns={[
                  {
                    Header: 'no.',
                    accessor: 'no',
                    style: { 'whiteSpace': 'unset', textAlign: 'center' },
                    width: 50,
                  },
                  {
                    Header: 'วันที่',
                    accessor: 'scan_date',
                    style: { 'whiteSpace': 'unset', textAlign: 'center' },
                  },
                  {
                    Header: 'หมายเลขเอกสาร',
                    style: { 'whiteSpace': 'unset', textAlign: 'center' },
                    accessor: 'document_no'

                  },
                  {
                    Header: 'ประเภทเอกสาร',
                    style: { 'whiteSpace': 'unset' },
                    accessor: 'document_type_name'
                  },
                  {
                    Header: 'พิมพ์',
                    minWidth: 50,
                    accessor: 'document_image',
                    Cell: (props) => {
                      return (
                        <Button style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                          color='teal'
                          icon='print'
                          onClick={() => {
                            printURL(props.original.document_image);
                          }}
                        />
                      )
                    }
                  },
                  {
                    Header: 'View',
                    minWidth: 50,
                    style: { 'whiteSpace': 'unset', textAlign: 'center' },
                    Cell: (props) => {
                      return (
                        <Button style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                          color='yellow'
                          icon='eye'
                          onClick={() => this.showScannedDoc(props)}
                        />
                      )
                    }
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </div>
      </CardLayout >
    )
  }

}

CardScannedDocument.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  scannedDoc: [],
  onGetScannedDocument: () => { },
  scannedDocLoading: false,
  scannedDocData: [],
  onGetScannedDocumentOptions: () => { },
  scannedDocOptions: {},
  onSearchScannedDoc: () => { },
  onGetSearchUserList: () => { },
  userList: [],
  userListLoading: false,
  doctorList: [],
  onGetSearchDoctorList: () => { },
  onPaginationChange: () => { },
  onGetDocumentType: () => { },
};

export default injectIntl(CardScannedDocument);
