import React from 'react';
import { useIntl } from "react-intl";
import { Table, Label, Segment } from 'semantic-ui-react'

const CardDischargeNursingView = (props) => {
  const intl = useIntl();

  const { dischargePlanning } = props;

  const nursing = dischargePlanning.nursing ? dischargePlanning.nursing : {}
  const summary = dischargePlanning.summary ? dischargePlanning.summary : {}
  const questions = nursing.question ? nursing.question : [];

  return (
    <Segment>
      <div style={{ maxHeight: 480, overflowY: 'scroll' }}>
        <Label size='big'>Nursing Discharge Planning Summary</Label>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                1. สภาพผู้ป่วยก่อนจำหน่าย
             </Table.Cell>
              <Table.Cell>
                {summary.predischarge_condition}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                2. อุปกรณ์การแพทย์ที่จำหน่ายพร้อมผู้ป่วย
             </Table.Cell>
              <Table.Cell>
                {nursing[questions[1]] ? nursing[questions[1]].items.map((items, index) => {
                  if (items.checked) {
                    return <div key={index}>{items.name} {items?.answer || ""}</div>;
                  }
                  return ''
                }): null}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                3. ปัญหาที่ต้องได้รับการดูแลต่อเนื่องที่บ้านและความช่วยเหลือที่ให้
             </Table.Cell>
              <Table.Cell>
                {nursing[questions[2]] ? nursing[questions[2]].items.map((items, index) => {
                  let data:any[] = []
                  if (items.is_advise) {
                    data.push('Provide Advice')
                  }
                  if(items.is_forward){
                    data.push('Refer')
                  }
                  return (
                    <div key={index}>
                      {index+1}. {items?.problem} {data.length > 0 && 
                        <>ความช่วยเหลือ <b>{data.join(", ")}</b> {items?.forward && <>: {items.forward}</>}</>
                      }
                    </div>
                  )
                }): null}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                4. การจำหน่าย
             </Table.Cell>
              <Table.Cell>
                {summary.ended_date}&ensp;{summary.ended_time}&ensp;{summary.discharged_from}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                5. การนัดตรวจครั้งต่อไป
             </Table.Cell>
              <Table.Cell>
                {nursing[questions[4]] && nursing[questions[4]]?.nextAppointments}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                6. สิ่งที่ผู้ป่วยได้รับก่อนจำหน่าย
             </Table.Cell>
              <Table.Cell>
                {nursing[questions[5]] ? nursing[questions[5]].items.map((items, index) => {
                  if (!items.checked) {
                    return '';
                  }
                  return (
                    <div key={index}>
                      {items.name} {items.answer || ""}
                    </div>
                  )
                }) : null}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                7. จำหน่ายออกจากโรงพยาบาลโดย
             </Table.Cell>
              <Table.Cell>
                {summary.discharge_status}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Label size='big'>In case of Death</Label>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                1. วันที่ถึงแก่กรรม
             </Table.Cell>
              <Table.Cell>
                {summary.death_date}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}>
                2. วันตรวจศพ
             </Table.Cell>
              <Table.Cell>
                {summary.corpse_identify}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div>&ensp;<span style={{ fontWeight: 'bold', fontSize: 16 }}>{intl.formatMessage({ id: "ผู้บันทึกจำหน่าย" })}</span>&emsp; {nursing.edit_user}&emsp;{nursing.edited}</div>
      </div>
    </Segment>
  )
}

CardDischargeNursingView.defaultProps = {
  hideHeaderIcon: false,
  dischargePlanning: {},
};

export default CardDischargeNursingView;
