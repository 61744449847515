import React from 'react';
import { useIntl } from "react-intl";
import { Table, Segment } from 'semantic-ui-react'

const styles = {
  gridHeader: {
    backgroundColor: '#2F3E52',
    color: '#FFFFFF',
  },
  subGridHeader: {
    backgroundColor: '#BDBDBD'
  },
  table: {
    maxHeight: 470,
    overflow: 'auto',
    display: 'block',
    width: '100%'
  },
  tableBody: {
    backgroundColor: '#F2F2F2'
  },
  tableTitle: {
    fontWeight: 'bold'
  }
}

const CardNurseDischargeTeachingView = (props) => {

  const { dischargePlanning } = props;

  const teaching = dischargePlanning.teaching ? dischargePlanning.teaching : {}
  const questions = teaching.question ? teaching.question : [];

  if(Object.keys(teaching).length === 0){
    return (
      <Segment>
        No Data.
      </Segment>
    )
  }

  const generateSubRow = (teachingItem) => {
    if(!Array.isArray(teachingItem.items)){
      return ''
    }
    if(Object.keys(teachingItem.items).length === 0){
      return ''
    }
    
    return teachingItem.items.map( item => {
      return ( 
      <Table.Row style={styles.tableBody}>
        <Table.Cell style={styles.tableTitle} collapsing>
          {'- ' + item.name || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_relative || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_relative_name || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_method || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_before || ""} {item.sub_before_user_name || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_first || ""} {item.sub_first_user_name || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_second || ""} {item.sub_second_user_name || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {item.sub_home || ""} {item.sub_home_user_name || ""}
        </Table.Cell>
      </Table.Row>
      )
    })
   
  }

  return (
    <Segment>
      <div style={{ maxHeight: 480, overflow: 'scroll' }}>
        <Table celled>
          <Table.Header style={styles.gridHeader}>
            <Table.Row>
              <Table.Cell collapsing>
                ผู้ป่วยและครอบครัวได้รับการสอน/คำแนะนำ
            </Table.Cell>
              <Table.Cell collapsing>
                ผู้รับการสอน
            </Table.Cell>
              <Table.Cell collapsing>
                ชื่อผู้รับ
            </Table.Cell>
              <Table.Cell collapsing>
                วิธีการให้ความรู้
            </Table.Cell>
              <Table.Cell collapsing>
                ระดับความรู้ก่อนสอบ
            </Table.Cell>
              <Table.Cell collapsing>
                ระดับความรู้ สอบครั้งที่ 1
            </Table.Cell>
              <Table.Cell collapsing>
                ระดับความรู้ สอบครั้งที่ 2
            </Table.Cell>
              <Table.Cell collapsing>
                ระดับความรู้สอบก่อนกลับบ้าน
            </Table.Cell>
            </Table.Row>
          </Table.Header>

          {questions.map((items, index) => {
            console.log(" teaching:", teaching[items])
            return (
              <Table.Body>
                <Table.Row key={index} style={{...styles.subGridHeader}}>
                  <Table.Cell style={{...styles.tableTitle, ...styles.subGridHeader}}>
                    {teaching[items].no + '. ' + teaching[items].name}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_relative}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_relative_name}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_method}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_before || ""} {teaching[items].main_before_user_name || ""}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_first || ""} {teaching[items].main_first_user_name || ""}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_second || ""} {teaching[items].main_second_user_name || ""}
                  </Table.Cell>
                  <Table.Cell>
                  {teaching[items].main_home || ""} {teaching[items].main_home_user_name || ""}
                  </Table.Cell>
                </Table.Row>
                { generateSubRow(teaching[items]) }
              </Table.Body>
            )
          })}
        </Table>
      </div>
    </Segment>
  )
}

CardNurseDischargeTeachingView.defaultProps = {
  hideHeaderIcon: false,
  dischargePlanning: {},
  children: [],
};

export default CardNurseDischargeTeachingView;
