import React, { useState, useRef, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

import CardPatientAssessment from "react-lib/apps/PTM/CardPatientAssessment";
import CardFallRiskAssessment from "react-lib/apps/PTM/CardFallRiskAssessment";
import CardPainAssessment from "react-lib/apps/PTM/CardPainAssessment";
import CardNutritionAssessment from "react-lib/apps/PTM/CardNutritionAssessment";
import CardReassessment from "react-lib/apps/PTM/CardReassessment";
// import CardNurseNote from "react-lib/apps/PTM/CardNurseNote";
import CardNurseNote from "react-lib/apps/HISV3/PTM/CardNurseNote";
import CardReAssessmentHistory from "react-lib/apps/HISV3/ADM/CardReAssessmentHistory";

import * as CONSTANT from "react-lib/utils/constant";
import { Form, Label, Menu, Input, Button } from "semantic-ui-react";
import { formatDate } from "react-lib/utils/dateUtils";
import { formatDropdown } from "react-lib/utils";
import RightLabel from "react-lib/apps/common/RightLabel";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ComboBox from "react-lib/apps/common/ComboBox";
import SearchBox from "react-lib/apps/common/SearchBox";
import PureReactTable from "../common/PureReactTable";
import moment from "moment";
import { useIntl } from "react-intl";

const CardFormViewer = forwardRef((props, ref) => {
  const intl = useIntl();
  let todayDate = formatDate(moment());
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(false);
  const [encounterType, setEncounterType] = useState("ALL");
  const [formTypeChoices, setFormTypeChoices] = useState([]);
  const [formType, setFormType] = useState(null);
  const [encounterText, setEncounterText] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [nurseNoteList, setNurseNoteList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [error, setError] = useState(null);
  const [openCard, setOpenCard] = useState(null);
  const [selectEncounterId, setSelectEncounterId] = useState(null);
  const [nurseNoteData, setNurseNoteData] = useState(null);

  const doctorRef = useRef();
  const patientAssessRef = useRef();
  const fallRiskAssessRef = useRef();
  const painAssessRef = useRef();
  const nutritionAssessRef = useRef();
  const reassessRef = useRef();
  const nurseNoteRef = useRef();

  useEffect(() => {
    props.runSequence({ sequence: "ReAssessment", restart: true });
  }, []);

  const handleEncounterType = (e, { name }) => {
    setEncounterType(name);
  };

  const handleTypeEncounter = (e) => {
    const { value } = e.target;
    setEncounterText(value);
  };

  useEffect(() => {
    getFormType();
    return () => {
      isMounted.current = null;
    };
  }, []);

  useEffect(() => {
    getFormDataNurseNote();
  }, [props.patientData.patient_id]);

  useEffect(() => {
    props.onOpenCard?.(openCard, selectEncounterId);
  }, [openCard]);

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, []);

  const getFormType = async () => {
    const [data, error] = await props.controller.getFormType();
    if (isMounted.current) {
      let options = formatDropdown(data?.items || [], "name", "id");
      options.push({
        key: "NURSE",
        text: "Nurse's Note",
        value: "NURSE",
      });
      setFormTypeChoices(options);
    }
  };

  const getDoctor = async ({ searchText }) => {
    const [data, error] = await props.controller.getDoctor({
      nameCode: searchText,
    });
    return [data, error];
  };

  const getFormDataNurseNote = async () => {
    if (!isMounted.current) {
      return;
    }

    setIsLoading(true);
    setSelectedRow(0);
    let doctor = doctorRef.current?.getId();
    console.log("getFormDataNurseNote doctorRef.current?.getId() = doctor =>", doctor);
    let patientType = encounterType;
    if (encounterType === CONSTANT.ENCOUNTER_TYPE.ALL) {
      patientType = null;
    }
    const [data, error] = await props.controller.getFormDataNurseNote({
      encounterType: patientType,
      encounterNo: encounterText,
      formType,
      doctor,
      startDate,
      endDate,
      patient: props.patientData.patient_id,
    });
    if (isMounted.current) {
      if (data) {
        let newItems = data.items.filter(
          (item: any) =>
            ![
              "CardPregnancyAssessment",
              "CardPreAssessment",
              "CardPatientFamilyTreatmentPlanning",
            ].includes(item.code)
        );
        let arr = [...newItems];
        for (let index in arr) {
          if (!arr[index].id) {
            arr[index].id = "fakeId" + index;
          }
        }
        setNurseNoteList(arr);
      } else if (error) {
        setError(error);
        setNurseNoteList([]);
      }
      setIsLoading(false);
      return [data, error];
    }
  };

  const handleSearch = () => {
    setOpenCard(null);
    getFormDataNurseNote();
  };

  const handleSelectNurseNote = async (data: any) => {
    await handleCloseCard();
    switch (data.code) {
      case CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_DISCHARGE_SUMMARY:
        // Cant find example, Cant fint data in db, Cant find place to save
        // Dont think in QML will work since there is no PTM/CardDischargeSummary.qml
        // But there is ADM&DPI/CardPreAssessment.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_PLANNING:
        // Not work in QML because there is no PTM/discharge_planning.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_TEACHING:
        // Not work in QML because there is no PTM/discharge_teaching.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_NURSING:
        // Not work in QML because there is no PTM/discharge_nursing.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_PAIN_IPD:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_PAIN_IPD);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_RISK_FALL_IPD:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_RISK_FALL_IPD);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_SLEEP_IPD:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_SLEEP_IPD);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT:
        // Not work in QML because there is no PTM/CardPreAssessment.qml
        // But there is ADM/CardPreAssessment.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD);
        break;
      case ADDITION_FORM_TYPE.CARD_NURSE_NOTE:
        setNurseNoteData(data);
        setSelectEncounterId(data.encounter_id);
        setOpenCard(ADDITION_FORM_TYPE.CARD_NURSE_NOTE);
        break;
      default:
        break;
      // case nurseNote
    }
  };

  const handleCloseCard = () => {
    setOpenCard(null);
    return null;
  };

  return (
    <CardLayout
      headerColor="teal"
      titleText="เอกสารการพยาบาล"
      closeable={props.closeable}
      onClose={props.onClose}
      loading={isLoading}
      dimmerStyle={{ minWidth: "max-content" }}
    >
      <Form>
        <ErrorMessage error={error} />
        <Form.Group inline>
          <Form.Field width={3}>
            <Label>{intl.formatMessage({ id: "ค้นหา" })}</Label>
            <RightLabel>{intl.formatMessage({ id: "ประเภทผู้ป่วย" })}</RightLabel>
          </Form.Field>
          <Form.Field width={3}>
            <Menu inverted size="tiny" className="bold" widths={3}>
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.OPD}
                color="green"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.OPD}
                onClick={handleEncounterType}
              />
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.IPD}
                color="green"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.IPD}
                onClick={handleEncounterType}
              />
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.ALL}
                color="orange"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.ALL}
                onClick={handleEncounterType}
              />
            </Menu>
          </Form.Field>
          <Form.Field width={5}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <div style={{ marginRight: "20px", fontWeight: "bold" }}>
                <RightLabel>Encounter</RightLabel>
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  fluid
                  testid="txtEncounter"
                  onChange={handleTypeEncounter}
                  value={encounterText}
                />
              </div>
            </div>
          </Form.Field>
          <Form.Field width={5}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <div style={{ marginRight: "20px", fontWeight: "bold" }}>
                <RightLabel>{intl.formatMessage({ id: "ประเภทเอกสาร" })}</RightLabel>
              </div>
              <div style={{ width: "100%" }}>
                <ComboBox
                  fluid
                  search
                  placeholder="แสดงทุกประเภท"
                  clearable
                  options={formTypeChoices}
                  onChange={(e, { value }) => setFormType(value)}
                />
              </div>
            </div>
          </Form.Field>
          <Form.Field>
            <Button content="ค้นหา" color="blue" fluid onClick={handleSearch} />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={3}>
            <RightLabel>{intl.formatMessage({ id: "แพทย์เจ้าของไข้" })}</RightLabel>
          </Form.Field>
          <Form.Field width={4}>
            <SearchBox ref={doctorRef} onGetSearchOptions={getDoctor} textField="name_code" />
          </Form.Field>
          <Form.Field width={3} />
          <Form.Field width={3}>
            <div style={{ width: "20%", fontWeight: "bold" }}>
              {intl.formatMessage({ id: "ตั้งแต่" })}
            </div>
            <div style={{ width: "80%" }}>
              <DateTextBox value={startDate} onChange={(date) => setStartDate(date)} />
            </div>
          </Form.Field>
          <Form.Field width={3}>
            <div style={{ width: "20%", fontWeight: "bold" }}>
              {intl.formatMessage({ id: "ถึง" })}
            </div>
            <div style={{ width: "80%" }}>
              <DateTextBox value={endDate} onChange={(date) => setEndDate(date)} />
            </div>
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        testid="nurseNoteTable"
        showPagination={false}
        className=""
        pageSize={nurseNoteList ? (nurseNoteList.length < 1 ? 1 : nurseNoteList.length) : 1}
        data={nurseNoteList}
        style={styles.nurseNoteTable}
        selectedClassName="blueSelectedRow"
        onSelect={(originalRow: any) => {
          setSelectedRow(originalRow);
          if (props.selectNoteOnClick) {
            handleSelectNurseNote(originalRow);
          }
        }}
        selectedRow={selectedRow}
        onEnter={(originalRow: any) => {
          if (props.selectNoteOnClick) {
            setSelectedRow(originalRow);
          }
          handleSelectNurseNote(originalRow);
        }}
        columns={[
          {
            Header: "ประเภท",
            accessor: "name",
            // minWidth: 150,
          },
          {
            Header: "วันที่",
            accessor: "created",
            // minWidth: 135,
          },
          {
            Header: "Encounter",
            accessor: "encounter_number",
            // minWidth: 75,
          },
          {
            Header: "แพทย์",
            accessor: "doctor_name",
            // minWidth: 165,
          },
          {
            Header: "แผนก",
            accessor: "division",
            // minWidth: 150,
          },
          {
            Header: "สถานะเอกสาร",
            accessor: "status",
            // minWidth: 120,
          },
          {
            Header: "ผู้บันทึกเอกสาร",
            accessor: "edit_user_name",
            // minWidth: 150,
          },
        ]}
      />
      <br />
      <br />
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT && (
        <CardPatientAssessment
          ref={patientAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.patientAssessmentController}
          encounterId={selectEncounterId}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT && (
        <CardFallRiskAssessment
          ref={fallRiskAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.fallRiskAssessmentController}
          encounterId={selectEncounterId}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT && (
        <CardPainAssessment
          ref={painAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.painAssessmentController}
          encounterId={selectEncounterId}
          painOptions={props.masterOptions?.pain}
          painFreqOptions={props.masterOptions?.painFreq}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT && (
        <CardNutritionAssessment
          ref={nutritionAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.nutritionAssessmentController}
          encounterId={selectEncounterId}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT && (
        <CardReassessment
          ref={reassessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.reassessmentController}
          encounterId={selectEncounterId}
          patientEducationOptions={props.masterOptions?.patientEducation}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_PAIN_IPD && (
        <CardReAssessmentHistory
          // seq
          runSequence={props.runSequence}
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          masterOptions={props.masterOptions}
          title={{ code: "PAIN", name: intl.formatMessage({ id: "การประเมินความเจ็บปวด" }) }}
          data={props.ReAssessmentSequence?.painDataHistory}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_RISK_FALL_IPD && (
        <CardReAssessmentHistory
          // seq
          runSequence={props.runSequence}
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          masterOptions={props.masterOptions}
          title={{
            code: "RISK_FALL",
            name: intl.formatMessage({ id: "การประเมินความเสี่ยงต่อการพลัดตกหกล้ม" }),
          }}
          data={props.ReAssessmentSequence?.riskFallDataHistory}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT_SLEEP_IPD && (
        <CardReAssessmentHistory
          // seq
          runSequence={props.runSequence}
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          masterOptions={props.masterOptions}
          title={{ code: "SLEEP", name: intl.formatMessage({ id: "การพักผ่อนนอนหลับ" }) }}
          data={props.ReAssessmentSequence?.sleepDataHistory}
          languageUX={props.languageUX}
        />
      )}
      {openCard === ADDITION_FORM_TYPE.CARD_NURSE_NOTE && (
        // <CardNurseNote
        //   ref={nurseNoteRef}
        //   onClose={handleCloseCard}
        //   patientData={props.patientData}
        //   nurseNoteData={nurseNoteData}
        //   controller={props.nurseNoteController}
        //   encounterId={selectEncounterId}
        //   django={props.django}
        //   needPatientRefresh={() => {
        //     getFormDataNurseNote();
        //   }}
        //   onPrintNurseNote={(data: any) => {
        //     props.onEvent({
        //       message: "handleNurseNoteReport",
        //       params: {
        //         card: "cardNurseNote",
        //         encounter: props.selectedEncounter?.id,
        //         nurseNoteData: data?.[0],
        //         nurseNoteList: data,
        //         selectedRow: selectedRow,
        //       },
        //     });
        //   }}
        // />
        <CardNurseNote
          // Medreconcile Check
          divisionType={props.divisionType}
          medReconcileCheck={props.medReconcileCheck}
          medReconcileIndex={props.medReconcileIndex}
          onEvent={props.onEvent}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          setProp={props.setProp}
          selectedNurseNote={props.selectedNurseNote}
          nurseNoteFocusOptions={props.nurseNoteFocusOptions}
          nurseNoteFilter={props.nurseNoteFilter}
          django={props.django}
          selectedEncounter={props.selectedEncounter}
          openModNurseNoteForm={props.openModNurseNoteForm}
          buttonLoadCheck={props.buttonLoadCheck}
          nurseNoteLoading={props.nurseNoteLoading}
          nurseNoteController={props.nurseNoteController}
          patientData={props.patientData}
          isNurseForm={true}
          languageUX={props.languageUX}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD && props.cardPreAssessment}
    </CardLayout>
  );
});

const ADDITION_FORM_TYPE = {
  CARD_NURSE_NOTE: "CardNurseNote",
};

const styles = {
  nurseNoteTable: {
    height: "150px",
  },
};

CardFormViewer.defaultProps = {
  closeable: true,
  onClose: () => {},
  onOpenCard: () => {},
  controller: {},
  patientData: {},
  patientAssessmentController: {},
  fallRiskAssessmentController: {},
  painAssessmentController: {},
  nutritionAssessmentController: {},
  reassessmentController: {},
  nurseNoteController: {},
  selectNoteOnClick: false,
  cardPreAssessment: null,
};

CardFormViewer.propTypes = {
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  onOpenCard: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  patientAssessmentController: PropTypes.object,
  fallRiskAssessmentController: PropTypes.object,
  painAssessmentController: PropTypes.object,
  nutritionAssessmentController: PropTypes.object,
  reassessmentController: PropTypes.object,
  nurseNoteController: PropTypes.object,
  selectNoteOnClick: PropTypes.bool,
  cardPreAssessment: PropTypes.element,
};

CardFormViewer.displayName = "CardFormViewer";
export default React.memo(CardFormViewer);
