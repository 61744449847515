import React from 'react';
import CardLayout from '../common/CardLayout';
import FilterDateRange from '../common/FilterDateRange'
import { Form, Tab } from 'semantic-ui-react';
import CardNurseNoteView from './CardNurseNoteView';
import CardPatientAssessmentView from './CardPatientAssessmentView';
import CardDischargePlanningTabView from './CardDischargePlanningTabView';
import CardDischargePlanningView from './CardDischargePlanningView';
import CardDischargeTeachingView from './CardDischargeTeachingView';
import CardMultiDisciplinaryView from '../PTM/CardMultiDisciplinaryView';
import CardNurseDischargeTeachingView from './CardNurseDischargeTeachingView';
import CardDischargeNursingView from './CardDischargeNursingView';
import CardANCRecordView from './CardANCRecordView';
import CardNursingDiagnosisView from './CardNursingDiagnosisView';
import CardPreAssessment from "react-lib/apps/HISV3/ADM/CardPreAssessment";
import CardReAssessment from "react-lib/apps/HISV3/ADM/CardReAssessment";
import { useIntl } from "react-intl";
import CardNursingDiagnosis from 'react-lib/apps/HISV3/PTM/CardNursingDiagnosis';

const styles = {
  tab: {
    width: '105%',
    fontSize: 13.5
  },
  documentType: {
    fontWeight: 'bold',
    color: '#2F80ED',
    fontSize: 16
  }
}

const NURSE_RECORD_ACTIVE_INDEX = {
  NURSE_NOTE: 0,
  PATIENT_ASSESSMENT: 1,
  NURSING_DISCHARGE_SUMMARY: 2,
  DISCHARGE_TEACHING: 3,
  MULTIDISCIPLINARY: 4,
  ANC_RECORD: 5,
  NURSING_DIAGNOSIS: 6,
};

const HIDE_FILTER = [NURSE_RECORD_ACTIVE_INDEX.NURSING_DISCHARGE_SUMMARY, NURSE_RECORD_ACTIVE_INDEX.DISCHARGE_TEACHING, NURSE_RECORD_ACTIVE_INDEX.ANC_RECORD]

const CardNurseRecord = React.forwardRef((props, ref) => {

  const intl = useIntl();

  const nursingDiagnosisRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    nursingDiagnosisRef: nursingDiagnosisRef
  }));

  const panes = [
    {
      menuItem: 'Nurse\'s Note',
      render: () => (
        <CardNurseNoteView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          encounterType={props.encounterType}
          nurseNote={props.nurseNote}
          onGetNurseNote={props.onGetNurseNote}
          nurseNoteLoading={props.nurseNoteLoading}
          enableMargin={false}
          languageUX={props.languageUX}
        />
      )
    },
    {
      menuItem: 'OPD Assessment',
      render: () => (
        <CardPatientAssessmentView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetPatientAssessment={props.onGetPatientAssessment}
          patientAssessment={props.patientAssessmentData}
          patientAssessmentLoading={props.patientAssessmentLoading}
          clinicalTermData={props.clinicalTermData}
          enableMargin={false}
          encounterType={props.encounterType}
          encounterId={props.encounterId}
          languageUX={props.languageUX}
        />
      )
    },
    {
      menuItem: 'Nursing Discharge Summary',
      render: () => (
        <CardDischargePlanningTabView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetDischargePlanning={props.onGetDischargePlanning}
          dischargePlanningLoading={props.dischargePlanningLoading}
          enableMargin={false}
          languageUX={props.languageUX}
        >
          <CardDischargePlanningView
            key='dischargePlanning'
            dischargePlanning={props.dischargePlanning}
            languageUX={props.languageUX}
          />
          <CardNurseDischargeTeachingView
            key='dischargeTeaching'
            dischargePlanning={props.dischargePlanning}
            languageUX={props.languageUX}
          />
          <CardDischargeNursingView
            key='dischargeSummary'
            dischargePlanning={props.dischargePlanning}
            languageUX={props.languageUX}
          />
        </CardDischargePlanningTabView>
      )
    },
    {
      menuItem: 'Patient Education (OPD)',
      render: () => (
        <CardDischargeTeachingView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetDischargeTeaching={props.onGetDischargeTeaching}
          reassessmentLoading={props.reassessmentLoading}
          reassessmentData={props.reassessmentData}
          enableMargin={false}
          encounterType={props.encounterType}
          languageUX={props.languageUX}
        />
      )
    },
    {
      menuItem: 'Multidisciplinary',
      render: () => (
        <CardMultiDisciplinaryView
          controller={props.crdMultiDisciplinaryController}
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetMultidisciplinary={props.onGetMultidisciplinary}
          multidisciplinaryLoading={props.multidisciplinaryLoading}
          multidisciplinaryData={props.multidisciplinaryData}
          enableMargin={false}
          encounterId={props.encounterId}
          onPrintMultidisciplinary={props.onPrintMultidisciplinary}
          languageUX={props.languageUX}
        />
      )
    },
    {
      menuItem: 'ANC Record',
      render: () => (
        <CardANCRecordView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          getANCRecordPDF={props.getANCRecordPDF}
          encounterId={props.encounterId}
          cardLayoutClassName='noCardMargin'
          languageUX={props.languageUX}
        />
      )
    },
    {
      menuItem: 'Nursing Diagnosis',
      render: () => (
        <CardNursingDiagnosis
          isPHV={true}
          divisionType={props.divisionType}
          django={props.django}
          medReconcileCheck={props.medReconcileCheck}
          medReconcileIndex={props.medReconcileIndex}
          buttonLoadCheck={props.buttonLoadCheck}
          onEvent={props.handleEvent}
          selectedEncounter={props.selectedEncounter}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          setProp={props.setProp}
          NursingDiagnosisSequence={props.NursingDiagnosisSequence}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          encounterId={props.encounterId}
          languageUX={props.languageUX}
        />
        // <CardNursingDiagnosisView
        //   closeable={false}
        //   toggleable={false}
        //   hideHeaderIcon={true}
        //   defaultBackground={false}
        //   getANCRecordPDF={props.getANCRecordPDF}
        //   encounterId={props.encounterId}
        //   cardLayoutClassName='noCardMargin'
        //   getNurseDiagnosisResultGroup={props.getNurseDiagnosisResultGroup}
        //   getNurseDiagnosisPDF={props.getNurseDiagnosisPDF}
        //   ref={nursingDiagnosisRef}
        //   languageUX={props.languageUX}
        // />
      )
    },
    {
      menuItem: "Admission Assessment",
      render: () => (
        <CardPreAssessment
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedPatient={props.patientDataAPI?.id}
          selectedEncounter={props.selectedEncounter}
          closeSave={true}
          // seq
          runSequence={props.runSequence}
          encounterId={props.encounterId}
          emrId={props.emrId}
          PreAssessmentSequence={props.PreAssessmentSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          isPHV={true}
          preAssessmentOptions={props.preAssessmentOptions}
          preAssessmentActionLog={props.preAssessmentActionLog}
          subICDController={props.subICDController}
          languageUX={props.languageUX}
        />
      ),
    },
    {
      menuItem: "Re-assessment (IPD)",
      render: () => (
        <CardReAssessment
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          closeSave={true}
          // seq
          runSequence={props.runSequence}
          ReAssessmentSequence={props.ReAssessmentSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          selectedEncounter={props.encounterId}
          reAssessmentOptions={props.reAssessmentOptions}
          languageUX={props.languageUX}
        />
      ),
    },
  ]

  return (
    <CardLayout
      closeable={props.closeable}
      toggleable={props.toggleable}
    >
      <Form>
        {/* <Form.Group inline>
          <Form.Field>
            ประเภท
        </Form.Field>
          <Dropdown
            search
            selection
            placeholder={intl.formatMessage({ id: "แสดงทั้งหมด" })}
          />
        </Form.Group> */}
        <Form.Group inline>
          <Form.Field width={2} style={styles.documentType}>
            ประเภทเอกสาร
          </Form.Field>
          <Form.Field >
            <FilterDateRange
              style={{ display: HIDE_FILTER.includes(props.activeIndex) ? 'none' : null }}
              onStartDateChange={props.onStartDateChange}
              onEndDateChange={props.onEndDateChange}
              onFilterDate={props.onNurseRecordSearch}
            />
          </Form.Field>
          {/* <Form.Field>
            <Button
              color="teal"
              content={intl.formatMessage({ id: "พิมพ์" })}
              icon="print"
              labelPosition="left"
              onClick={props.onPrintClick}
            />
          </Form.Field> */}
        </Form.Group>
      </Form>
      <Tab
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{ vertical: true, style: styles.tab, className: 'yellowHover' }}
        panes={panes}
        onTabChange={(e, { activeIndex }) => props.setActiveIndex(activeIndex)}
      />
    </CardLayout>
  )
})

CardNurseRecord.defaultProps = {
  closeable: true,
  toggleable: true,
  nurseNote: [],
  onGetNurseNote: () => { },
  onGetPatientAssessment: () => { },
  onPrintClick: () => {},
  onPrintMultidisciplinary: () => {},
  encounterId: null,
  getNurseDiagnosisResultGroup: () => [null, null],
};

export default CardNurseRecord;