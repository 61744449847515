import React, { useEffect, useMemo } from 'react';
import CardLayout from '../common/CardLayout';
import ReactTable from 'react-table-6';
import { Form } from 'semantic-ui-react';
import { useIntl } from "react-intl";


const CardNurseNoteView = (props) => {
  useEffect(() => {
    props.onGetNurseNote({})
  }, [])

  const columns = useMemo(() => {
    let result = []
    if (["IPD", "SS"].includes(props.encounterType)) {
      result.push({
        Header: 'เวร',
        accessor: 'working_shift',
        style: { 'whiteSpace': 'unset' },
        width: 100
      });
    }
    result.push(
      {
        Header: 'วันและเวลาบันทึก',
        style: { 'whiteSpace': 'unset' },
        width: 125,
        Cell: (data: any) => {
          return (
            <Form >
              <Form.Field>
                {data.original.formatted_date}
              </Form.Field>
            </Form>
          );
        },
      },
      {
        Header: 'Focus',
        style: { 'whiteSpace': 'unset' },
        width: 125,
        Cell: (data: any) => {
          return (
            <Form >
              <Form.Field>
                {data.original.focus}
              </Form.Field>
            </Form>
          );
        },
      },
      {
        Header: 'การรักษาพยาบาล',
        accessor: 'plan',
        style: { 'whiteSpace': 'unset' },
        width: 150,
        Cell: (data: any) => {
          return (
            <Form >
              <Form.Field>
                <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: data.original.plan }} />
              </Form.Field>
            </Form>
          );
        },
      },
      {
        Header: 'ข้อวินิจฉัยการพยาบาลและข้อมูลสนับสนุน',
        accessor: 'diagnosis',
        style: { 'whiteSpace': 'unset' },
        width: 250,
        Cell: (data: any) => {
          return (
            <Form >
              <Form.Field>
                <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: data.original.diagnosis }} />
              </Form.Field>
            </Form>
          );
        },
      },
      {
        Header: 'กิจกรรมการพยาบาลและการประเมินผล',
        accessor: 'goal',
        style: { 'whiteSpace': 'unset' },
        width: 250
      },
      {
        Header: 'Nurse',
        accessor: 'edit_user',
        style: { 'whiteSpace': 'unset' },
        width: 130,
        Cell: (data: any) => {
          return (
            <Form >
              <Form.Field>
                {data.original.edit_user + '(' + data.original.nurse_code + ')'}
              </Form.Field>
            </Form>
          );
        },
      },
      {
        Header: 'Division',
        accessor: 'division_code',
        style: { 'whiteSpace': 'unset' },
        width: 70,
      }
    )
    return result;
  }, [props.encounterType])

  return (
    <CardLayout
      titleText={'Nurse\'s Note'}
      headerColor='blue'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.nurseNoteLoading}
      enableMargin={props.enableMargin}
    >
      <ReactTable
        style={{ height: 470, backgroundColor: '#FFFFFF' }}
        showPagination={false}
        pageSize={props.nurseNote ? (props.nurseNote.length < 5 ? 5 : props.nurseNote.length) : 5}
        data={props.nurseNote}
        columns={columns}
      />
    </CardLayout>
  )
}

CardNurseNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  nurseNote: [],
  onGetNurseNote: () => { },
  enableMargin: true,
};

export default CardNurseNoteView;
