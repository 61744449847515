import React, { useEffect, useState } from "react";
import {
  Header,
  Table,
  Loader,
  Dimmer,
  Button,
  Icon
} from "semantic-ui-react";
import CardLayout from '../common/CardLayout';
import PropTypes from "prop-types";
import Parser from 'html-react-parser';
import { filterVitalSignTypeByAge, getTableRowVitalSign } from "../HISV3/PTM/CardVitalSign";
import { SORT_ADR_ORDER } from "react-lib/apps/HISV3/TPD/sequence/Allergy";
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const EMRRowTable = ({ name, value }) => {

  var p = value.split("\n");
  var dict = {};
  var currentKey = "";

  if (value.includes(":")) {
    p.forEach((item, index) => {
      // Found Lab
      if (item.startsWith("-")) {
        let key = item.slice(1);
        key = key.slice(0, -1);
        key.trim();

        currentKey = key;
        dict[key] = [];
      } else {
        let itemTrimed = item.trim();

        let labCode = "";
        let labName = "";
        let resultLab = "";

        if (itemTrimed.startsWith("1.") && dict[currentKey].length > 0) {
          dict[currentKey].push({ labCode, labName, resultLab });
        }

        if (!currentKey.includes("ray")) {
          labCode = itemTrimed.match(/\[(.*?)]/)[1].trim();
          labName = itemTrimed.match(/](.*?)=/)[1].trim();
          resultLab = itemTrimed.match(/=(.*?)$/)[1].trim();
        } else {
          labName = itemTrimed.match(/](.*?)=/)[1].trim();
          resultLab = itemTrimed.match(/=(.*?)$/)[1].trim();
        }

        dict[currentKey].push({ labCode, labName, resultLab });
      }
    });
  }

  return (
    <Table.Row>
      <Table.Cell width={3}>
        <Header as="h5"> {name} </Header>
      </Table.Cell>
      {dict && Object.keys(dict).length > 0 ? (
        <Table.Cell width={13}>
          {
            Object.keys(dict).map((key, index) => {
              return (

                <Table compact key={key} color={"red"}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan={3}>{key}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {dict[key].map(item => {
                      if (!key.includes("ray")) {
                        return (
                          <>
                            <Table.Row key={item.labCode}>
                              <Table.Cell width={5}>
                                {" "}
                                {item.labCode}{" "}
                              </Table.Cell>
                              <Table.Cell width={6}>
                                {" "}
                                {item.labName}{" "}
                              </Table.Cell>
                              <Table.Cell width={5}>
                                {" "}
                                {item.resultLab}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Table.Row key={item.labCode}>
                              <Table.Cell width={6}>
                                {" "}
                                {item.labName}{" "}
                              </Table.Cell>
                              <Table.Cell width={10}>
                                {" "}
                                {item.resultLab}{" "}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        );
                      }
                    })}
                  </Table.Body>
                </Table>

              );
            })
          }
        </Table.Cell>
      ) : (
        <Table.Cell width={13}> {value} </Table.Cell>
      )}
    </Table.Row>
  );
};

const EMRRowTablePhysical = ({ name, value }) => {
  console.log('EMRRowTablePhysical name: ', name );
  console.log('EMRRowTablePhysical value: ', value );
  var dict = null;
  if (value.includes("(")) {
    let l = value.split(",");
    l.reverse().forEach((item, index) => {
      if (!item.includes("(")) {
        let i = index + 1;
        if (i <= l.length) {
          l[i] = l[i].concat(",", l[index]);
        }
      } else {
        l[index] = item.trim();
      }
    });

    l = l.reverse().filter(item => {
      if (!item.includes("(")) {
        return false;
      } else {
        return true;
      }
    });
    //  let g = ["GA(N/A)", " HEENT(Normal): pharynx and tonsils not injected↵LN not palpable", " CVS(N/A)", " Chest(Normal): clear", " Abdomen(Normal): soft, mild tender suprapubic, CVA not tender", " Ext(Normal): no arthritis", " LN(N/A)", " Skin(N/A)", " NS(N/A)"]
    //  let g = ["GA(N/A)", " HEENT(Normal): pharynx and tonsils not A(ON): injected↵LN not palpable", " CVS(N/A)", " Chest(Normal): clear", " Abdomen(Normal): soft, mild tender suprapubic, CVA not tender", " Ext(Normal): no arthritis", " LN(N/A)", " Skin(N/A)", " NS(N/A)"]
    dict = l.map(item => {
      let column = item.split(":");
      let one = column[0].split("(");
      let two = one && one.length > 1 ? one[1].slice(0, -1) : '';
      column.shift();
      let three = column.join(":");

      return { organ: one[0], status: two, description: three };
    });
  }

  return (
    <Table.Row key={name}>
      <Table.Cell width={3}>
        <Header as="h5"> {name} </Header>
      </Table.Cell>
      {dict ? (
        <Table.Cell width={13}>
          <Table compact>
            <Table.Body>
              {dict.map(item => {
                return (
                  <Table.Row key={item.organ}>
                    <Table.Cell width={5}> {item.organ} </Table.Cell>
                    <Table.Cell width={6}> {item.status} </Table.Cell>
                    <Table.Cell width={5}> {item.description} </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Table.Cell>
      ) : (
        <Table.Cell width={13}> {value} </Table.Cell>
      )}
    </Table.Row>
  );
};

const EMRRowSmoke = ({ name, value }) => {
  const intl = useIntl();
  const { smoke, smoke_freq, alcoholic_drink, alcoholic_drink_freq } = value;

  const checkFreq = (data:any) => {
    if (data === 1) {
      return "บางครั้ง"
    } else if (data === 2) {
      return "ประจำ"
    } else {
      return data
    }
  }

  return (
    <Table.Row key={name}>
      <Table.Cell width={3}>
        <Header as="h5"> {name} </Header>
      </Table.Cell>
      <Table.Cell width={13}>
        <label>
          {" "}
          <b>{intl.formatMessage({ id: "สูบบุหรี่" })}</b> {smoke} &ensp; &ensp;{checkFreq(smoke_freq)} &emsp; &emsp;
          <b>{intl.formatMessage({ id: "ดื่มสุรา" })}</b> {alcoholic_drink} &ensp; &ensp;{checkFreq(alcoholic_drink_freq)}
          {/* &emsp;&emsp;
          <b>{intl.formatMessage({ id: "การใช้สารเสพติด" })}</b>
          {""} &ensp; {" "} */}
        </label>
      </Table.Cell>
    </Table.Row>
  );
};

const EMRRowTextHTML = ({ name, value }) => {
  //
  let text = value.replace(/<[^>]+>/g, '')
  return (
    <Table.Row key={name}>
      <Table.Cell width={3}>
        <Header as="h5"> {name} </Header>
      </Table.Cell>
      <Table.Cell width={13}>
      {text}
      {/* <div className="content">{Parser(value)}</div> */}
      </Table.Cell>
    </Table.Row>
  );
};

const EMRRowText = ({ name, value }) => {
  // console.log('EMRRowText value: ', value);
  // console.log('EMRRowText name: ', name);
  return (
    <Table.Row key={name}>
      <Table.Cell width={3}>
        <Header as="h5"> {name} </Header>
      </Table.Cell>
      <Table.Cell width={13}>
        {typeof value === "string" && value.length > 0
          ? value.split("\n").map((i, key) => <p key={key}>{i}</p>)
          : Array.isArray(value) ? value.join(", ") : value}
      </Table.Cell>
    </Table.Row>
  );
};

const EMRRowCell = ({
  name,
  value,
}: {
  name: string;
  value: Record<string, any>[] | Record<string, any>[][];
}) => (
  <Table.Row key={name}>
    <Table.Cell width={3}>
      <Header as="h5"> {name} </Header>
    </Table.Cell>

    <Table.Cell style={{ padding: value.length === 0 ? "" : 0 }} width={13}>
      {value.length === 0 ? (
        "-"
      ) : (
        <Table style={{ backgroundColor: "transparent", border: "none" }}>
          <Table.Body>
            <Table.Row>
              {value.map((item, index) => (
                <Table.Cell
                  key={`cell-${index}`}
                  style={{
                    maxWidth: "95px",
                    minWidth: "95px",
                    textAlign: "center",
                    ...(name === "Vital sign" && { paddingBottom: "2px", paddingTop: "2px" }),
                  }}
                >
                  {Array.isArray(item) ? (
                    <>
                      <span style={{ color: item[0].color }}>{item[0].value}</span>
                      {"/"}
                      <span style={{ color: item[1].color }}>{item[1].value}</span>
                    </>
                  ) : (
                    <span style={{ color: item.color }}>{item.value}</span>
                  )}
                </Table.Cell>
              ))}
              <Table.Cell width={13} />
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </Table.Cell>
  </Table.Row>
);

const EMRRow = ({ emrSummary, emrArray }) => {
  const tableInvestigate = ["ผล Investigate ต่างๆ"];
  const tablePhysical = ["Physical Examination"];
  const combo = ["ประวัติสูบบุหรี่ /ประวัติดื่มสุรา"];
  const htmltag = ["Treatment"];
  return (
    <>
      {emrArray.map((item, index) => {
        if (tableInvestigate.includes(item)) {
          return (
            <EMRRowTable key={index} name={item} value={emrSummary[item]} />
          );
        } else if (tablePhysical.includes(item)) {
          // show Table
          return (
            <EMRRowTablePhysical
              key={index}
              name={item}
              value={emrSummary[item]}
            />
          );
        } else if (combo.includes(item)) {
          // Show combo
          return (
            <EMRRowSmoke key={index} name={item} value={emrSummary[item]} />
          );
        } else if (htmltag.includes(item)) {
          return <EMRRowTextHTML key={index} name={item} value={emrSummary[item]} />;
        } else if (Array.isArray(emrSummary[item])) {
          return <EMRRowCell key={index} name={item} value={emrSummary[item]} />;
        } else {
          // Show normal
          return <EMRRowText key={index} name={item} value={emrSummary[item]} />;
        }
      })}
    </>
  );
};

const CardEMROPDSummary = ({
  emrSummaryData,
  getEMRSummaryData,
  getEMRSummaryPrint,
  closeable,
  toggleable,
  hideHeaderIcon,
  isLoading,
  onPrintEMRSummary
}) => {
  // Don't change this key , it's very important on ภาษาไทย
  let emrArray = [
    "วันที่/เวลาที่เข้าพบแพทย์",
    "Vital sign",
    "Temperature",
    "Pulse",
    "Blood Pressure",
    "Respiratory Rate",
    "O2sat",
    "BMI",
    "ประวัติสูบบุหรี่ /ประวัติดื่มสุรา",
    "ประวัติการแพ้",
    "รายการยาที่ผู้ป่วยรับประทานอยู่ในปัจจุบัน",
    "Chief Complaint",
    // "โรคประจำตัว",
    // "ประวัติการทำผ่าตัด/หัตถการ",
    "Physician note (Present illness, Past history and relevant information)",
    "Past Illness",
    "Personal History",
    "Family History",
    "Physical Examination",
    // "Physical/General condition Signification Finding",
    "Diagnosis",
    "Medication",
    "Treatment",
    // "Surgical plan",
    "Appointment",
    "Discharge Status / Condition in case admit or transfer",
    "Patient and Family Education",
    "Barriers of data receiver",
    "Readiness and Willingness",
    "Teaching Method",
    "Education to",
    "Education Topic",
    "Physician",
    // "ผล Investigate ต่างๆ"
  ];

  let initSummary = emrArray.reduce((total, currentValue) => {
    let item = { [currentValue]: "N/A" };
    return { ...total, ...item };
  }, {});


  const [emrSummary, setEMRSummary] = useState(initSummary);
  const [isLoadingPrintReport, setIsLoadingPrintReport] = useState(false);

  // useEffect(() => {
  //
  //   getEMRSummaryData();
  // }, []);

  useEffect(() => {
    if (emrSummaryData.hasOwnProperty("emr")) {
      let newEMRSummary = extractSummarytoState();
      setEMRSummary(newEMRSummary);
    } else {
      setEMRSummary(initSummary)
    }
  }, [emrSummaryData]);

  const getVitalSignData = ()=> {
    const items: any[] = emrSummaryData.vitalSign.items || [];

    items.reverse();

    let rowData = getTableRowVitalSign({
      ...emrSummaryData,
      vitalSign: items,
    });

    rowData = filterVitalSignTypeByAge(rowData, Number(emrSummaryData.emr[0].age_year));

    const formattedVitalSigns = items.map((vitalSignDataItem) => {
      const dateTime = formatDatetime(vitalSignDataItem.date_time_utc, true);
      const measurement_id = `${vitalSignDataItem.id}`;

      return {
        ...vitalSignDataItem,
        dateTime,
        measurement_id,
      };
    });

    const latestVitalSigns = formattedVitalSigns.slice(-3);

    const vitalSignTypes = ["DP", "SP", ["PULSE", "PR"], "RR", ["TEMP", "BT"], "O2Sat", "BMI"].map(
      (code) => code.toString().split(",")
    );

    const getVitalSignValues = (signType: string[]) => {
      const matchedRow = rowData.find((row) => signType.includes(row.code));
      const signValues = latestVitalSigns.map((vitalSign) => {
        const data = matchedRow?.[vitalSign.id || ""];

        return data
          ? {
              color: data.color,
              value: `${data.formatted_string} ${
                ["TEMP", "BT"].toString() === signType.toString() ? "°C" : ""
              }`,
            }
          : { color: "", value: "" };
      });

      return [signType[0], signValues];
    };

    const vitalSignData: Record<string, any[]> = Object.fromEntries(
      vitalSignTypes.map((signType) => getVitalSignValues(signType))
    );

    vitalSignData.VS = latestVitalSigns.map((item) => ({ value: item.dateTime }));

    return vitalSignData;
  }

  const extractSummarytoState = () => {
    const newEMRSummary: Record<string, any> = initSummary;
    // TODO: Check
    //ประวัติสูบบุหรี่ /ประวัติดื่มสุรา: "******** !!! !!****"

    if (emrSummaryData && emrSummaryData.emr) {
      emrSummaryData.emr[1].forEach((item, index) => {
        newEMRSummary[item.question] = item.answer;
      });

      newEMRSummary["O2sat"] = emrSummaryData.emr[0]["O2sat"];
      newEMRSummary["BMI"] = emrSummaryData.emr[0]["bmi"];
      newEMRSummary["Blood Pressure"] = emrSummaryData.emr[0]["blood_pressure"];
      newEMRSummary["Temperature"] = emrSummaryData.emr[0]["body_temperature"];
      newEMRSummary["Pulse"] = emrSummaryData.emr[0]["pulse"];
      newEMRSummary["Respiratory Rate"] =
        emrSummaryData.emr[0]["respiratory_rate"];

      let smoke = emrSummaryData.emr[0]["smoking"];
      let smoke_freq = emrSummaryData.emr[0]["smoking_freq"];
      let alcoholic_drink = emrSummaryData.emr[0]["alcoholic_drink"];
      let alcoholic_drink_freq = emrSummaryData.emr[0]["alcoholic_drink_freq"];

      newEMRSummary["ประวัติสูบบุหรี่ /ประวัติดื่มสุรา"] = {
        smoke,
        smoke_freq,
        alcoholic_drink,
        alcoholic_drink_freq
      };

      const sortedAdr = (emrSummaryData?.adverseReaction?.items || []).sort(
        (a, b) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
      );
    
      newEMRSummary["ประวัติการแพ้"] = sortedAdr.map((acc) => acc.name as string).filter(Boolean).join(", ");
    }

    if (emrSummaryData?.vitalSign) {
      const vitalSignData = getVitalSignData();

      newEMRSummary["Vital sign"] = vitalSignData.VS;
      newEMRSummary.O2sat = vitalSignData.O2Sat;
      newEMRSummary.BMI = vitalSignData.BMI;
      newEMRSummary["Blood Pressure"] = vitalSignData.SP.map((_, index) => [
        vitalSignData.SP[index],
        vitalSignData.DP[index],
      ]);
      newEMRSummary.Temperature = vitalSignData.TEMP;
      newEMRSummary.Pulse = vitalSignData.PULSE;
      newEMRSummary["Respiratory Rate"] = vitalSignData.RR;
    }

    return newEMRSummary;
  };

  const handlePrint = param => {
    getEMRSummaryPrint();
  };

  const handlePrintReport = async () => {
    if (isLoadingPrintReport) {
      return;
    }

    setIsLoadingPrintReport(true);

    await onPrintEMRSummary({ emrArray, emrSummary, emrSummaryData });

    setIsLoadingPrintReport(false);
  };

  return (
    <CardLayout
      titleText="EMR Summary"
      closeable={closeable}
      toggleable={toggleable}
      hideHeaderIcon={hideHeaderIcon}
    >
      {emrSummaryData.encounterType === "OPD" && (
        <Button
          color="green"
          size="mini"
          loading={isLoadingPrintReport}
          style={{
            left: "10.5rem",
            position: "absolute",
            top: "8px",
          }}
          onClick={handlePrintReport}
        >
          <Icon name="print" />
          OPD Report
        </Button>
      )}

      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>

      {emrSummaryData.hasOwnProperty("emr") || emrSummaryData.hasOwnProperty("success") ? (
        <>
          {/* <div style={{ textAlign: "right" }}>
            <Button
              size="tiny"
              color="teal"
              content={intl.formatMessage({ id: "พิมพ์" })}
              icon="print"
              labelPosition="left"
              onClick={handlePrint}
            />
          </div> */}
          <Table striped celled size="small">
            <Table.Body>
              <EMRRow emrSummary={emrSummary} emrArray={emrArray} />
            </Table.Body>
          </Table>
        </>
      ) : null}
    </CardLayout>
  );
};

CardEMROPDSummary.defaultProps = {
  emrSummaryData: {},
  closeable: false,
  toggleable: false,
  hideHeaderIcon: true,
  getEMRSummaryData: () => {},
  getEMRSummaryPrint: () => {},
  onPrintEMRSummary: () => {},
  isLoading: false,
};

CardEMROPDSummary.propTypes = {
  emrSummaryData: PropTypes.object,
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  getEMRSummaryData: PropTypes.func,
  getEMRSummaryPrint: PropTypes.func,
  onPrintEMRSummary: PropTypes.func,
  isLoading: PropTypes.bool
};

export default CardEMROPDSummary;
