import React from "react";
import CardEMROPDSummary from "./CardEMROPDSummary";
import CardDischargeSummaryView from "./CardDischargeSummaryView";
import CardLayout from "../common/CardLayout";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Props
type CardEMRSummaryProps = {
  // OPD
  emrSummaryData?: Record<string, any>,
  getEMRSummaryData?: (e: any) => any,
  onPrintEMRSummary?: (e: any) => any,
  onPrintDischargeSummary: (e: any) => any,
  languageUX?: any;

  // Common
  closeable?: boolean,
  toggleable?: boolean,
  hideHeaderIcon?: boolean,
  isLoading?: boolean,

  masterData?: Record<string, any>,
};

const CardEMRSummary = (props:CardEMRSummaryProps) => {
  if (
    props.emrSummaryData &&
    props.emrSummaryData?.status &&
    props.emrSummaryData?.status === "NODISCHARGESUMMARY"
  ) {
    return (
      <CardLayout
        titleText="Discharge Summary -"
        closeable={false}
        toggleable={false}
        hideHeaderIcon={true}
      >
        <Dimmer active={props.isLoading} inverted>
          <Loader />
        </Dimmer>
        Encounter นี้ไม่สามารถดูข้อมูล Discharge Summary ได้
      </CardLayout>
    );
  }

  return (
    <>
      {props.emrSummaryData && props.emrSummaryData.hasOwnProperty("dischargeSummary") ? (
        <CardDischargeSummaryView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          showBtnPrint={true}
          encounterType={"IPD"}
          dischargeSummaryData={props.emrSummaryData}
          masterData={props.masterData}
          dischargeSummaryLoading={props.isLoading}
          onPrintDischargeSummary={props.onPrintDischargeSummary}
          languageUX={props.languageUX}
        />
      ) : (
        <CardEMROPDSummary
          emrSummaryData={props.emrSummaryData}
          getEMRSummaryData={props.getEMRSummaryData}
          onPrintEMRSummary={props.onPrintEMRSummary}
          isLoading={props.isLoading}
          closeable={false}
          toggleable={false}
          languageUX={props.languageUX}
        />
      )}
    </>
  );
};

export default CardEMRSummary;
